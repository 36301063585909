import React from 'react';

const NotFound = () => {
    return (
        <div className="grid min-h-screen place-items-center bg-blue-tech-hexagon bg-cover">
            <div>
                <p className="text-7xl">404</p>
                <p className="text-xl mt-5">Page not found</p>
            </div>
        </div>
    )
}

export default NotFound;