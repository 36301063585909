import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Sidenav, Nav, IconButton } from 'rsuite';
import { Creative, Peoples, Tools, CharacterLock, Exit, Page } from '@rsuite/icons';
import CloseIcon from '@rsuite/icons/Close';

const CampSidebar = ({ page, isMenu, setIsMenu }) => {
  const [activeKey, setActiveKey] = React.useState(page);

  const navigate = useNavigate();

  function logout() {
    localStorage.setItem("auth-token", "");
    localStorage.setItem("user-id", "");
    navigate("/login");
  }

  return (
    <Sidenav appearance="subtle">
      {
        isMenu ? (
          <Sidenav.Header>
            <IconButton onClick={() => setIsMenu(false)} className="mobile-menu-button close-button z-20 right-0 top-0" icon={<CloseIcon size="2em" />} />
          </Sidenav.Header>
        ) : null
      }
      <Sidenav.Body style={{ paddingTop: 10, paddingLeft: 10 }}>
        <Nav appearance="subtle" activeKey={activeKey} onSelect={setActiveKey} >
          <Nav.Item eventKey="manage" icon={<Peoples />} href="/manage">Manage kids</Nav.Item>
          <Nav.Item eventKey="book" icon={<Creative />} href="/book">Book a camp</Nav.Item>
          <Nav.Item eventKey="orders" icon={<Page />} href="/history">My registrations</Nav.Item>
          <Nav.Item eventKey="profile" icon={<Tools />} href="/profile">Edit profile</Nav.Item>
          <Nav.Item eventKey="password" icon={<CharacterLock />} href="/password">Change password</Nav.Item>
          <Nav.Item eventKey="logout" icon={<Exit />} onSelect={logout}>Logout</Nav.Item>
        </Nav>
      </Sidenav.Body>
    </Sidenav>
  )
}

export default CampSidebar;
