import React, { Fragment } from 'react';
import { Sidenav, Row, Button } from 'rsuite';
import moment from 'moment'

const CampCart = ({ cart, setStep }) => {
  return cart && (
    <Sidenav appearance="subtle">
      <Sidenav.Body style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
          {/* <Row>
              <p className="text-lg text-black mb-3">Subtotal: <b>${cart.reduce((total, item) => total + Number(item.price), 0)}</b></p>
          </Row> */}

          <Row>
              <Button
                  // block
                  appearance="primary"
                  onClick={cart.length === 0 ? null : () => setStep(2)}
                  disabled={cart.length === 0}
                  size="md"
                  style={{ width: '120px' }}
              >
                  Next
              </Button>
          </Row>

          <Row>
              <p className="text-base text-black mt-6 mb-2">Details:</p>
          </Row>

          {cart.map(item =>
              <Fragment key={item.week._id + item.kid._id}>
                <Row>
                    <b>{item.kid.firstName + " " + item.kid.lastName}</b>
                </Row>
                <Row>
                    {item.week.theme}
                    {/* {item.week.theme ? item.week.theme + ' (' + item.week.camp.name + ')' : item.week.camp.name} */}
                </Row>
                <Row>
                    {item.week.location.address}
                </Row>
                <Row>
                    {moment(item.week.startDate).format("DD/MM")} - {moment(item.week.endDate).format("DD/MM")}
                </Row>
                {/* <Row className="mb-2">
                    ${item.price}
                </Row> */}
              </Fragment>
          )}


        {/* </Grid> */}
      </Sidenav.Body>
    </Sidenav>
  )
}

export default CampCart;