import React, { useState } from 'react';
import { Panel } from 'rsuite';

export const CustomPanel = ({ children, expanded, ...rest }) => {
    const [open, setOpen] = useState(expanded);

    const handleClick = (e) => {
        if(e.target.className === "rs-panel-header") {
            setOpen((value) => !value);
        }
    }
    return (
        <Panel onClick={handleClick} expanded={open} {...rest}>
            {children}
        </Panel>
    );
};