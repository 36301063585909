import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Register from './components/Register';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import EditProfile from './components/EditProfile';
import NotFound from './components/NotFound';
import ChangePassword from './components/ChangePassword';
import ManageKids from './components/ManageKids';
import Book from './components/Book';
import Orders from './components/Orders';

import 'rsuite/dist/rsuite.min.css';

function MainApp() {

  return (
    <>

      <Routes>
        <Route exact path="/" element={<ManageKids />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/book" element={<Book />} />
        <Route path="/manage" element={<ManageKids />} />
        <Route path="/history" element={<Orders />} />
        <Route path="/profile" element={<EditProfile />} />
        <Route path="/password" element={<ChangePassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

    </>
  );
}

export default MainApp;
