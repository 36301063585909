import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MainApp from './MainApp';
import { BrowserRouter as Router } from 'react-router-dom';
import GA4React from "ga-4-react";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-MJLL72J'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <Router>
        <MainApp/>
    </Router>, 
    document.getElementById('root')
);

try {
    setTimeout(_ => {
      const ga4react = new GA4React("G-MNJLPPZZES");
      ga4react.initialize().catch(err => console.error(err));
    }, 4000);
  } catch (err) {
        console.error(err);
  }
  
