import React from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Form, FlexboxGrid, Button, ButtonToolbar } from 'rsuite';
import { Field } from './common/Field'
// import { searchAddressByPostalcode } from '../services/utilService';
import { Textarea } from './common/Textarea';
import { toast } from 'react-toastify';
import { Validator } from "@rsuite/validation";

const apiUrl = process.env.REACT_APP_SERVER_API_URL;

const model = Validator.SchemaModel({
  firstName: "required",
  lastName: "required",
  email: "required|email",
  phone: "required",
  postalCode: "required",//"required|regex:\\d{6}",
  address: "required",
  password: "required|min:6|regex:[A-Z]|regex:[a-z]|regex:[0-9]",
}, {
  "password.regex": "Should contain at least one number and have a mixture of uppercase and lowercase letters"
});

const Register = () => {

  const [formValue, setFormValue] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    postalCode: '',
    address: '',
    password: ''
  });

  const updateFormValue = (fv) => {
    // if (fv.postalCode !== formValue.postalCode && fv.postalCode.length === 6) {
      // searchAddressByPostalcode(fv, setFormValue);
    // } else {
      setFormValue(fv);
    // }
  }

  const navigate = useNavigate();

  const formRef = React.useRef();

  const register = (e) => {
    if (!formRef.current.check()) {
      return;
    }
    const newUser = {
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      email: formValue.email,
      phone: formValue.phone,
      postalCode: formValue.postalCode,
      address: formValue.address,
      password: formValue.password
    };

    axios.post(`${apiUrl}/users`, newUser)
      .then(() => {

        axios.post(`${apiUrl}/users/login`, {
          email: formValue.email, password: formValue.password
        }).then((loginResponse) => {
          localStorage.setItem("auth-token", loginResponse.data.token);
          localStorage.setItem("user-id", loginResponse.data.user.id);
          localStorage.setItem("cart", JSON.stringify([]));
          navigate("/");
          window.scrollTo(0, 0);
        });

      })
      .catch((error) => {
        toast.error(error.response.data.msg || 'Ooops! Something wrong happened. We are looking into it.', { position: "top-center" });
      });
  }


  return (

    <div className="flex w-full min-h-screen place-items-center justify-center bg-blue-tech-hexagon bg-cover">
      <Form
        ref={formRef}
        onChange={fv => updateFormValue(fv)}
        formValue={formValue}
        model={model}
        className="rounded-lg shadow-2xl border bg-white p-5 w-full max-w-342 lg:w-342"
        size="full"
      >
        <b>Dear parent, to register for the Newtonshow holiday camp, please create an account</b>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={24} style={{ marginTop: 10 }}>
            <Field
              name="firstName"
              placeholder="First name"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={24} style={{ marginTop: 10 }}>
            <Field
              name="lastName"
              placeholder="Last name"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={24} style={{ marginTop: 10 }}>
            <Field
              name="email"
              placeholder="Email"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={24} style={{ marginTop: 10 }}>
            <Field
              name="phone"
              placeholder="Mobile number"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={24} style={{ marginTop: 10 }}>
            <Field
              name="postalCode"
              placeholder="Postal code"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={24} style={{ marginTop: 10 }}>
            <Field
              name="address"
              placeholder="Address"
              accepter={Textarea}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={24} style={{ marginTop: 10 }}>
            <Field
              name="password"
              placeholder="Password"
              type='password'
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={24} style={{ marginTop: 20 }}>
            <ButtonToolbar>
              <Button appearance="primary" onClick={register} style={{ width: 300 }}>
                Sign Up
              </Button>
              <Link to="../login" className="mt-3 block w-full no-underline text-blue-500 text-sm text-center">Already have an account?</Link>
            </ButtonToolbar>
          </FlexboxGrid.Item>
        </FlexboxGrid>

      </Form>
    </div>
  )
}

export default Register;
