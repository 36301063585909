import React from 'react';
import { AutoComplete, DatePicker, FlexboxGrid, Radio, RadioGroup, Input, Form, Schema, Button, Modal, CheckPicker } from 'rsuite';
import { Field } from './common/Field'

const nationalities = ['Afghan',
'Albanian',
'Algerian',
'American',
'Andorran',
'Angolan',
'Antiguans',
'Argentinean',
'Armenian',
'Australian',
'Austrian',
'Azerbaijani',
'Bahamian',
'Bahraini',
'Bangladeshi',
'Barbadian',
'Barbudans',
'Batswana',
'Belarusian',
'Belgian',
'Belizean',
'Beninese',
'Bhutanese',
'Bolivian',
'Bosnian',
'Brazilian',
'British',
'Bruneian',
'Bulgarian',
'Burkinabe',
'Burmese',
'Burundian',
'Cambodian',
'Cameroonian',
'Canadian',
'Cape Verdean',
'Central African',
'Chadian',
'Chilean',
'Chinese',
'Colombian',
'Comoran',
'Congolese',
'Costa Rican',
'Croatian',
'Cuban',
'Cypriot',
'Czech',
'Danish',
'Djibouti',
'Dominican',
'Dutch',
'East Timorese',
'Ecuadorean',
'Egyptian',
'Emirian',
'Equatorial Guinean',
'Eritrean',
'Estonian',
'Ethiopian',
'Fijian',
'Filipino',
'Finnish',
'French',
'Gabonese',
'Gambian',
'Georgian',
'German',
'Ghanaian',
'Greek',
'Grenadian',
'Guatemalan',
'Guinea-Bissauan',
'Guinean',
'Guyanese',
'Haitian',
'Herzegovinian',
'Honduran',
'Hungarian',
'I-Kiribati',
'Icelander',
'Indian',
'Indonesian',
'Iranian',
'Iraqi',
'Irish',
'Israeli',
'Italian',
'Ivorian',
'Jamaican',
'Japanese',
'Jordanian',
'Kazakhstani',
'Kenyan',
'Kittian and Nevisian',
'Kuwaiti',
'Kyrgyz',
'Laotian',
'Latvian',
'Lebanese',
'Liberian',
'Libyan',
'Liechtensteiner',
'Lithuanian',
'Luxembourger',
'Macedonian',
'Malagasy',
'Malawian',
'Malaysian',
'Maldivian',
'Malian',
'Maltese',
'Marshallese',
'Mauritanian',
'Mauritian',
'Mexican',
'Micronesian',
'Moldovan',
'Monacan',
'Mongolian',
'Moroccan',
'Mosotho',
'Motswana',
'Mozambican',
'Namibian',
'Nauruan',
'Nepalese',
'New Zealander',
'Ni-Vanuatu',
'Nicaraguan',
'Nigerian',
'Nigerien',
'North Korean',
'Northern Irish',
'Norwegian',
'Omani',
'Pakistani',
'Palauan',
'Panamanian',
'Papua New Guinean',
'Paraguayan',
'Peruvian',
'Polish',
'Portuguese',
'Qatari',
'Romanian',
'Russian',
'Rwandan',
'Saint Lucian',
'Salvadoran',
'Samoan',
'San Marinese',
'Sao Tomean',
'Saudi',
'Scottish',
'Senegalese',
'Serbian',
'Seychellois',
'Sierra Leonean',
'Singaporean',
'Slovakian',
'Slovenian',
'Solomon Islander',
'Somali',
'South African',
'South Korean',
'Spanish',
'Sri Lankan',
'Sudanese',
'Surinamer',
'Swazi',
'Swedish',
'Swiss',
'Syrian',
'Taiwanese',
'Tajik',
'Tanzanian',
'Thai',
'Togolese',
'Tongan',
'Trinidadian or Tobagonian',
'Tunisian',
'Turkish',
'Tuvaluan',
'Ugandan',
'Ukrainian',
'Uruguayan',
'Uzbekistani',
'Venezuelan',
'Vietnamese',
'Welsh',
'Yemenite',
'Zambian',
'Zimbabwean']
const schools = [
    'Admiralty Primary School',
    'Ahmad Ibrahim Primary School',
    'Ai Tong School',
    'Alexandra Primary School',
    'Anchor Green Primary School',
    'Anderson Primary School',
    'Anglo-Chinese School (Junior)',
    'Anglo-Chinese School (Primary)',
    'Angsana Primary School',
    'Ang Mo Kio Primary School',
    'Balestier Hill Primary School',
    'Beacon Primary School',
    'Bedok Green Primary School',
    'Bendemeer Primary School',
    'Blangah Rise Primary School',
    'Boon Lay Garden Primary School',
    'Bukit Panjang Primary School',
    'Bukit Timah Primary School',
    'Bukit View Primary School',
    'Canberra Primary School',
    'Canossa Catholic Primary School',
    'Cantonment Primary School',
    'Casuarina Primary School',
    'Catholic High School (Primary)',
    'Cedar Primary School',
    'Changkat Primary School',
    'CHIJ (Katong) Primary',
    'CHIJ (Kellock)',
    'CHIJ Our Lady of Good Counsel',
    'CHIJ Our Lady of the Nativity',
    'CHIJ Our Lady Queen of Peace',
    'CHIJ Primary (Toa Payoh)',
    'CHIJ St. Nicholas Girls\' School',
    'Chongfu School',
    'Chongzheng Primary School',
    'Chua Chu Kang Primary School',
    'Clementi Primary School',
    'Compassvale Primary School',
    'Concord Primary School',
    'Coral Primary School',
    'Corporation Primary School',
    'Da Qiao Primary School',
    'Damai Primary School',
    'Dazhong Primary School',
    'De La Salle School',
    'East Coast Primary School',
    'East Spring Primary School',
    'East View Primary School',
    'Edgefield Primary School',
    'Elias Park Primary School',
    'Endeavour Primary School',
    'Eunos Primary School',
    'Evergreen Primary School',
    'Fairfield Methodist School (Primary)',
    'Farrer Park Primary School',
    'Fengshan Primary School',
    'Fernvale Primary School',
    'First Toa Payoh Primary School',
    'Frontier Primary School',
    'Fuchun Primary School',
    'Fuhua Primary School',
    'Gan Eng Seng Primary School',
    'Geylang Methodist School (Primary)',
    'Gongshang Primary School',
    'Greendale Primary School',
    'Greenridge Primary School',
    'Greenwood Primary School',
    'Guangyang Primary School',
    'Haig Girls\' School',
    'Holy Innocents\' Primary School',
    'Henry Park Primary School',
    'Hong Wen School',
    'Horizon Primary School',
    'Hougang Primary School',
    'Huamin Primary School',
    'Innova Primary School',
    'Jiemin Primary School',
    'Jing Shan Primary School',
    'Junyuan Primary School',
    'Jurong Primary School',
    'Jurong West Primary School',
    'Juying Primary School',
    'Keming Primary School',
    'Kheng Cheng School',
    'Kong Hwa School',
    'Kranji Primary School',
    'Kuo Chuan Presbyterian Primary School',
    'Lakeside Primary School',
    'Lianhua Primary School',
    'Loyang Primary School',
    'MacPherson Primary School',
    'Maha Bodhi School',
    'Maris Stella High School',
    'Marsiling Primary School',
    'Marymount Convent School',
    'Mayflower Primary School',
    'Mee Toh School',
    'Meridian Primary School',
    'Methodist Girls\' School (Primary)',
    'Montfort Junior School',
    'Nan Chiau Primary School',
    'Nan Hua Primary School',
    'Nanyang Primary School',
    'Ngee Ann Primary School',
    'Naval Base Primary School',
    'New Town Primary School',
    'Northland Primary School',
    'Northoaks Primary School',
    'North Spring Primary School',
    'North View Primary School',
    'North Vista Primary School',
    'Oasis Primary School',
    'Opera Estate Primary School',
    'Palm View Primary School',
    'Park View Primary School',
    'Pasir Ris Primary School',
    'Paya Lebar Methodist Girls\' School (Primary)',
    'Pei Chun Public School',
    'Pei Hwa Presbyterian Primary School',
    'Pei Tong Primary School',
    'Peiying Primary School',
    'Pioneer Primary School',
    'Poi Ching School',
    'Princess Elizabeth Primary School',
    'Punggol Cove Primary School',
    'Punggol Green Primary School',
    'Punggol Primary School',
    'Punggol View Primary School',
    'Qifa Primary School',
    'Qihua Primary School',
    'Queenstown Primary School',
    'Radin Mas Primary School',
    'Raffles Girls\' Primary School',
    'Red Swastika School',
    'Riverside Primary School',
    'River Valley Primary School',
    'Rivervale Primary School',
    'Rosyth School',
    'Rulang Primary School',
    'Sengkang Green Primary School',
    'Sembawang Primary School',
    'Si Ling Primary School',
    'Seng Kang Primary School',
    'Shuqun Primary School',
    'Singapore Chinese Girls’ School (Primary)',
    'South View Primary School',
    'Springdale Primary School',
    'Stamford Primary School',
    'St. Andrew\'s Junior School',
    'St. Anthony\'s Canossian Primary School',
    'St. Anthony\'s Primary School',
    'St. Gabriel\'s Primary School',
    'St. Hilda\'s Primary School',
    'St. Joseph\'s Institution Junior',
    'St. Margaret\'s Primary School',
    'St. Stephen\'s School',
    'Tampines North Primary School',
    'Tampines Primary School',
    'Tanjong Katong Primary School',
    'Tao Nan School',
    'Teck Ghee Primary School',
    'Teck Whye Primary School',
    'Telok Kurau Primary School',
    'Temasek Primary School',
    'Townsville Primary School',
    'Unity Primary School',
    'Valour Primary School',
    'Waterway Primary School',
    'Wellington Primary School',
    'West Grove Primary School',
    'West Spring Primary School',
    'Westwood Primary School',
    'West View Primary School',
    'White Sands Primary School',
    'Woodgrove Primary School',
    'Woodlands Primary School',
    'Woodlands Ring Primary School',
    'Xinghua Primary School',
    'Xingnan Primary School',
    'Xinmin Primary School',
    'Xishan Primary School',
    'Yangzheng Primary School',
    'Yew Tee Primary School',
    'Yio Chu Kang Primary School',
    'Yishun Primary School',
    'Yu Neng Primary School',
    'Yuhua Primary School',
    'Yumin Primary School',
    'Zhangde Primary School',
    'Zhenghua Primary School',
    'Zhonghua Primary School',
    'Anglo-Chinese School (International) Singapore',
    'Australian International School Singapore',
    'Canadian International School (Singapore)',
    'Chatsworth International School',
    'Dover Court International School',
    'Dulwich College Singapore',
    'EtonHouse International Education Group',
    'French School of Singapore',
    'GEMS World Academy (Singapore)',
    'German European School Singapore',
    'Global Indian International School Singapore',
    'Hwa Chong International',
    'ISS International School',
    'Invictus International School',
    'Nexus International School Singapore',
    'North London Collegiate School',
    'SJI International School',
    'Singapore American School',
    'Singapore Korean International School',
    'Stamford American International School',
    'Swiss School in Singapore',
    'Tanglin Trust School',
    'The Japanese School Singapore',
    'The Perse School Singapore',
    'United World College of South East Asia',
    'Waseda Shibuya Senior High School in Singapore'
];


const model = Schema.Model({
    firstName: Schema.Types.StringType().isRequired('This field is required.'),
    lastName: Schema.Types.StringType().isRequired('This field is required.'),
    gender: Schema.Types.StringType().isRequired('This field is required.'),
    dob: Schema.Types.DateType().isRequired('This field is required.'),
    nationality: Schema.Types.StringType().isRequired('This field is required.'),
    school: Schema.Types.StringType().isRequired('This field is required.')
});


function getDateMinusYears(offsetYears) {
    var d = new Date();
    d.setFullYear(d.getFullYear() - offsetYears);
    return d;
}

const KidInfo = ({ kid, createKid, setKid, removeKid, setCreateKid, createOrUpdateKid }) => {

    const [formValue, setFormValue] = React.useState({
        firstName: kid ? kid.firstName : "",
        lastName: kid ? kid.lastName : "",
        gender: kid ? kid.gender : null,
        dob: kid ? new Date(kid.dob) : null,
        nationality: kid ? kid.nationality : "",
        school: kid ? kid.school : "",
        foodPreferences: kid ? kid.foodPreferences : [],
        medicalConditions: kid ? kid.medicalConditions : "",
    });

    const formRef = React.useRef();

    const handleClose = () => {
        kid ? setKid(null) : setCreateKid(false)
    }

    const handleSubmit = () => {
        if (!formRef.current.check()) {
            console.error('Form Error');
            return;
        }

        createOrUpdateKid(kid ? kid._id : null, {
            user: localStorage.getItem("user-id"),
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            gender: formValue.gender,
            dob: formValue.dob,
            nationality: formValue.nationality,
            school: formValue.school,
            foodPreferences: formValue.foodPreferences,
            medicalConditions: formValue.medicalConditions
        });
        handleClose();
    };

    return (
        <Modal open={kid || createKid} onClose={handleClose} backdrop="static">
            <Modal.Header>
            </Modal.Header>

            <Form
                ref={formRef}
                onChange={setFormValue}
                formValue={formValue}
                model={model}
                layout="horizontal">

                <Field
                    placeholder="First name (child)"
                    name="firstName"
                    label="First name (child)"
                />

                <Field
                    placeholder="Last name (child)"
                    name="lastName"
                    label="Last name (child)"
                />

                <Field
                    name="gender"
                    label="Gender"
                    accepter={RadioGroup}
                    inline
                >
                    <Radio value="M">Male</Radio>
                    <Radio value="F">Female</Radio>
                </Field>

                <Field
                    placeholder="DD/MM/YYYY"
                    name="dob"
                    label="Date of birth"
                    accepter={DatePicker}
                    disabledDate={(d) => d > getDateMinusYears(2)}
                    format="dd/MM/yyyy"
                    oneTap
                    ranges={[]}
                    calendarDefaultDate={getDateMinusYears(9)}
                    style={{ width: 150 }}
                />

                <Field
                    placeholder="Nationality"
                    name="nationality"
                    label="Nationality"
                    accepter={AutoComplete}
                    data={nationalities}
                />

                <Field
                    placeholder="School"
                    name="school"
                    label="School"
                    accepter={AutoComplete}
                    data={schools}
                />

                <Field
                    placeholder="No preferences"
                    name="foodPreferences"
                    label="Food preferences"
                    // accepter={Input}
                    accepter={CheckPicker}
                    data={[
                        { value: "vegetarian", label: "Vegetarian" },
                        { value: "gluten-free", label: "Gluten-free" },
                        { value: "halal", label: "Halal" },
                        { value: "other", label: "Other" },
                    ]}
                    searchable={false}
                />

                <Field
                    placeholder="No conditions"
                    name="medicalConditions"
                    label="Medical conditions"
                    accepter={Input}
                    // accepter={CheckPicker}
                    // data={[
                    //     { value: "allergy", label: "Allergy" },
                    //     { value: "asthma", label: "Asthma" },
                    //     { value: "inhaler", label: "Inhaler" }
                    // ]}
                    // searchable={false}
                    // placement="autoVerticalStart"
                />

                <Form.Group>
                    <FlexboxGrid justify="space-between">
                        {
                        // kid ?
                        //     <Button
                        //         appearance="primary"
                        //         onClick={removeKid}
                        //         color="red">Delete</Button>
                        //     :
                            <div></div>
                        }

                        <FlexboxGrid >
                            <Button appearance="primary" onClick={handleSubmit}>Save Changes</Button>
                            <Button appearance="subtle" onClick={handleClose}>Cancel</Button>
                        </FlexboxGrid>
                    </FlexboxGrid>
                </Form.Group>

            </Form>
        </Modal>

    )
}

export default KidInfo;
