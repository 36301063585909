import React from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import { Form, Schema, Button } from 'rsuite';

import { checkIfLoggedIn } from '../services/utilService';
import CampLayout from './common/CampLayout'
import { Field } from './common/Field'
import { toast } from 'react-toastify';

const apiUrl = process.env.REACT_APP_SERVER_API_URL;

const model = Schema.Model({
    oldPassword: Schema.Types.StringType().isRequired('This field is required.'),
    newPassword: Schema.Types.StringType().isRequired('This field is required.'),
    confirmedNewPassword: Schema.Types.StringType()
        .addRule((value, data) => {
            if (value !== data.newPassword) {
                return false;
            }

            return true;
        }, 'Passwords do not match')
        .isRequired('This field is required.')
});

const ChangePassword = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        checkIfLoggedIn();

        if (!localStorage.getItem("user-id")) {
            navigate("/login");
        }
    }, []);

    const [formValue, setFormValue] = React.useState({
        oldPassword: '',
        newPassword: '',
        confirmedNewPassword: ''
    });

    const handleSubmit = () => {
        if (!formRef.current.check()) {
            return;
        }

        axios.put(`${apiUrl}/users/${localStorage.getItem('user-id')}/password`,
            {
                oldPassword: formValue.oldPassword,
                newPassword: formValue.newPassword
            })
            .then(() => {
                toast.success("Your password is successfully updated");
                setFormValue({
                    oldPassword: '',
                    newPassword: '',
                    confirmedNewPassword: ''
                });
            })
            .catch((error) => {
                toast.error(error.response.data.msg);
            });
    };

    const formRef = React.useRef();

    return (
        <CampLayout page="password" cart={JSON.parse(localStorage.getItem("cart"))}>
            <Form
                ref={formRef}
                onChange={setFormValue}
                formValue={formValue}
                model={model}
                layout="horizontal">

                <Field
                    name="oldPassword"
                    label="Old password"
                    type="password"
                    style={{ width: 250 }}
                />
                <Field
                    name="newPassword"
                    label="New password"
                    type="password"
                    style={{ width: 250 }}
                />
                <Field
                    name="confirmedNewPassword"
                    label="Confirm new password"
                    type="password"
                    style={{ width: 250 }}
                />

                <Field
                    name="saveButton"
                    accepter={Button}
                    appearance="primary"
                    onClick={handleSubmit}
                >
                    Save Changes
                </Field>

            </Form>
        </CampLayout>
    )
}

export default ChangePassword;