import React from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import { Form, Schema, Button } from 'rsuite';

import { checkIfLoggedIn, searchAddressByPostalcode } from '../services/utilService';
import CampLayout from './common/CampLayout'
import { Field } from './common/Field'
import { Textarea } from './common/Textarea'
import { toast } from 'react-toastify';

const model = Schema.Model({
    firstName: Schema.Types.StringType().isRequired('This field is required.'),
    lastName: Schema.Types.StringType().isRequired('This field is required.'),
    phone: Schema.Types.StringType().isRequired('This field is required.'),
    postalCode: Schema.Types.NumberType().isRequired('This field is required.'),
    address: Schema.Types.StringType().isRequired('This field is required.'),
});

const apiUrl = process.env.REACT_APP_SERVER_API_URL;

const EditProfile = () => {
    const navigate = useNavigate();

    const [formValue, setFormValue] = React.useState({
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        postalCode: ""
    });

    React.useEffect(() => {
        checkIfLoggedIn();

        if (!localStorage.getItem("user-id")) {
            navigate("/login");
        } else {
            let mounted = true;
            axios.get(`${apiUrl}/users/${localStorage.getItem('user-id')}`)
                .then((response) => {
                    if (mounted) {
                        setFormValue({
                            email: response.data.email,
                            firstName: response.data.firstName,
                            lastName: response.data.lastName,
                            phone: response.data.phone,
                            address: response.data.address,
                            postalCode: response.data.postalCode
                        });
                    }
                });
            return () => mounted = false;
        }
    }, []);

    const handleSubmit = () => {
        if (!formRef.current.check()) {
            return;
        }

        axios.put(`${apiUrl}/users/${localStorage.getItem('user-id')}`,
            {
                firstName: formValue.firstName,
                lastName: formValue.lastName,
                phone: formValue.phone,
                postalCode: formValue.postalCode,
                address: formValue.address
            })
            .then(() => {
                toast.success("Profile details are successfully updated")
            })
            .catch(() => {
                toast.error("Sorry, something went wrong. Try again later.")
            });
    };

    const updateFormValue = (fv) => {
        if (fv.postalCode !== formValue.postalCode && fv.postalCode.length === 6) {
            searchAddressByPostalcode(fv, setFormValue);
        } else {
            setFormValue(fv);
        }
    }

    const formRef = React.useRef();

    return (
        <CampLayout page="profile" cart={JSON.parse(localStorage.getItem("cart"))}>
            <Form
                ref={formRef}
                onChange={fv => updateFormValue(fv)}
                formValue={formValue}
                model={model}
                layout="horizontal">

                <Field
                    name="email"
                    label="Email"
                    disabled="true"
                />
                <Field
                    name="firstName"
                    label="First name"
                />
                <Field
                    name="lastName"
                    label="Last name"
                />
                <Field
                    name="phone"
                    label="Mobile number"
                />

                <Field
                    name="postalCode"
                    label="Postal code"
                />

                <Field
                    name="address"
                    label="Address"
                    accepter={Textarea}
                    rows={3}
                />

                <Field
                    name="saveButton"
                    accepter={Button}
                    appearance="primary"
                    onClick={handleSubmit}
                >
                    Save Changes
                </Field>

            </Form>

        </CampLayout>
    )
}

export default EditProfile;